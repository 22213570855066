import React from 'react'
import Layout from '../components/Layout';
import Clients from "../components/Clients"
import { StaticImage } from 'gatsby-plugin-image';
import SEO from '../components/SEO';

const about = () => {
    return (
      <div>
        <Layout>
          <SEO title="About" />
          <div className="flex flex-col items-center justify-center pt-32 lg:pt-48 px-6 lg:px-16">
            <h1 className="text-6xl font-bold">We are MediaSeal</h1>
            <p className="lg:w-100 text-xl font-light pt-8">
              We unlock growth opportunities for our clients by developing
              innovative media solutions that connect brands to target audience.
            </p>

            <div className="flex pt-16">
              <div className="flex flex-col w-1/3  ">
                <StaticImage
                  alt="insight driven media planning"
                  src="../images/clients/mediaseal_1.jpeg"
                  placeholder="blurred"
                  className="lg:mt-8 lg:mx-6"
                />

                <StaticImage
                  alt="insight driven media planning"
                  src="../images/clients/mediaseal_2.jpeg"
                  placeholder="blurred"
                  className=" lg:mt-6 lg:mx-6"
                />

                {/* <StaticImage
                  alt="insight driven media planning"
                  src="../images/about/IMG_20201219_151028_879.jpg"
                  placeholder="blurred"
                  className=" mt-6 mx-6"
                /> */}
              </div>

              <div className="flex flex-col w-1/3 pt-8 lg:pt-24">
                <StaticImage
                  alt="insight driven media planning"
                  src="../images/clients/mediaseal_3.jpeg"
                  placeholder="blurred"
                  className=" lg:mt-6 lg:mx-6"
                />

                {/* <StaticImage
                  alt="insight driven media planning"
                  src="../images/clients/new_image.jpeg"
                  placeholder="blurred"
                  className=" lg:mt-6 lg:mx-6"
                /> */}

                {/* <StaticImage
                  alt="insight driven media planning"
                  src="../images/clients/mediaseal_1.jpeg"
                  placeholder="blurred"
                  className=" mt-6 mx-6"
                /> */}
              </div>

              <div className="flex flex-col w-1/3">
                <StaticImage
                  alt="insight driven media planning"
                  src="../images/clients/mediaseal_5.jpeg"
                  placeholder="blurred"
                  className=" lg:mt-6 lg:mx-6"
                />

                <StaticImage
                  alt="insight driven media planning"
                  src="../images/clients/mediaseal_6.jpeg"
                  placeholder="blurred"
                  className=" lg:mt-6 lg:mx-6"
                />

                {/* <StaticImage
                  alt="insight driven media planning"
                  src="../images/clients/mediaseal_1.jpeg"
                  placeholder="blurred"
                  className=" mt-6 mx-6"
                /> */}
              </div>
            </div>
          </div>

          <div className="flex flex-col  lg:py-32 px-6 lg:px-16">
            <div className="flex">
              <h1 className="text-3xl lg:text-6xl font-bold  items-center justify-center pb-8 ">
                Our Story
              </h1>
              {/* <div className="w-full lg:w-2/3"></div> */}
            </div>
            <div className="flex  lg:flex-row flex-col">
              <div className="w-full lg:w-1/3"></div>
              <div className="fle flex-col w-full lg:w-2/3">
                <article className=" font-normal pt-8  lg:pr-16">
                  In August 2017, We REBRANDED. We were first known as Starcom
                  Media. A baptismal name we adopted in the year 2000. Our
                  rebranding initiative was a repositioning exercise to better
                  serve our clients. We are now a part of the largest
                  independent agency network – Worldwide Partners, with over 70
                  independent and diversified market services agencies in over
                  40 countries who support the world's most heralded brands
                  across 90 industry verticals. Our strategies are globally
                  informed and exceptionally relevant locally.
                  <br></br>
                  <br></br>
                  Outside the shores of the Nigerian market, we have established
                  formidable partnerships to cater for brands in the West
                  African sub-region. This fact indicates that we follow our
                  clients into new markets to fully explore the marketing
                  potential for brands in our portfolio.
                  <br></br>
                  <br></br>
                  With over 20 years in the game, MediaSeal sits pretty well
                  across major markets in the region as one of the frontline
                  media shops in Nigeria with the capability to service clients
                  beyond the shores of the Nigerian market in a seamless,
                  bespoke, and well-measured fashion.
                </article>
              </div>
            </div>
            <div className="flex py-24">
              <div className="flex flex-col w-1/3  ">
                <StaticImage
                  alt="insight driven media planning"
                  src="../images/about/IMG-20210208-WA0006.jpg"
                  placeholder="blurred"
                  className="lg:mt-8 lg:mx-6"
                />

                <StaticImage
                  alt="insight driven media planning"
                  src="../images/about/IMG-20210208-WA0021.jpg"
                  placeholder="blurred"
                  className=" lg:mt-6 lg:mx-6"
                />

                <StaticImage
                  alt="insight driven media planning"
                  src="../images/about/IMG-20210208-WA0013.jpg"
                  placeholder="blurred"
                  className=" lg:mt-6 lg:mx-6"
                />

                <StaticImage
                  alt="insight driven media planning"
                  src="../images/webimages/IMG-20210208-WA0032.jpg"
                  placeholder="blurred"
                  className=" mt-6 mx-6"
                />
              </div>

              <div className="flex flex-col w-1/3 pt-8 lg:pt-24">
                <StaticImage
                  alt="insight driven media planning"
                  src="../images/about/7da5a3fa50923db0eb4afe753b3c16f6.jpg"
                  placeholder="blurred"
                  className=" lg:mt-6 lg:mx-6"
                />

                <StaticImage
                  alt="insight driven media planning"
                  src="../images/about/md.jpeg"
                  placeholder="blurred"
                  className=" lg:mt-6 lg:mx-6"
                />

                {/* <StaticImage
                  alt="insight driven media planning"
                  src="../images/clients/mediaseal_1.jpeg"
                  placeholder="blurred"
                  className=" mt-6 mx-6"
                /> */}
              </div>

              <div className="flex flex-col w-1/3">
                <StaticImage
                  alt="insight driven media planning"
                  src="../images/about/IMG-20210208-WA0034.jpg"
                  placeholder="blurred"
                  className=" lg:mt-6 lg:mx-6"
                />

                <StaticImage
                  alt="insight driven media planning"
                  src="../images/about/IMG-20210208-WA0044.jpg"
                  placeholder="blurred"
                  className=" lg:mt-6 lg:mx-6"
                />

                <StaticImage
                  alt="insight driven media planning"
                  src="../images/about/IMG-20210208-WA0029.jpg"
                  placeholder="blurred"
                  className=" lg:mt-6 lg:mx-6"
                />

                <StaticImage
                  alt="insight driven media planning"
                  src="../images/webimages/IMG-20210208-WA0038.jpg"
                  placeholder="blurred"
                  className=" mt-6 mx-6"
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col py-12 lg:py-32 px-6 lg:px-16 bg-black text-white">
            <div className="flex">
              <h1 className="text-3xl lg:text-6xl font-bold w-full items-center justify-center  pb-8">
                Our Core Principles
              </h1>
              {/* <div className="w-full lg:w-2/3"></div> */}
            </div>
            <div className="flex  lg:flex-row flex-col items-center justify-center ">
              <StaticImage
                alt="insight driven media planning"
                src="../images/webimages-2/core_values.png"
                placeholder="blurred"
                className="lg:mx-6"
                // style={{
                //   maxWidth: 700,
                // }}
              />
            </div>
          </div>

          <div className="flex flex-col px-6 lg:px-16 py-12 lg:py-32">
            <div className="flex lg:flex-row flex-col">
              <h1 className="text-3xl lg:text-6xl font-bold w-full items-center justify-center pb-8 ">
                Our Clients
              </h1>
              {/* <div className="w-full lg:w-2/3"></div> */}
            </div>
            <div className="flex flex-col pt-8 md:flex-row items-center justify-between ">
              <div className="w-48">
                <StaticImage
                  src="../images/clients/amstel.jpeg"
                  placeholder="blurred"
                  className=" mr-6 p-2"
                  alt="Amstel and Media Seal WPI"
                  // width={120}
                />
              </div>

              <div className="w-48 flex items-center justify-center">
                <StaticImage
                  src="../images/clients/SMOOV.png"
                  placeholder="blurred"
                  className="mr-6 p-2 "
                  alt="Smoov and  Media Seal WPI"
                  style={{
                    width: 90,
                  }}
                />
              </div>

              <div className="w-48">
                <StaticImage
                  src="../images/clients/chi.png"
                  placeholder="blurred"
                  className=" mr-6 p-2"
                  alt="Chi and Media Seal WPI"
                />
              </div>

              <div className="w-48">
                <StaticImage
                  src="../images/clients/gulder.jpeg"
                  placeholder="blurred"
                  className=" mr-6 p-2"
                  alt="Gulder and Media Seal WPI"
                />{" "}
              </div>

              <div className="w-48">
                <StaticImage
                  src="../images/clients/star.jpeg"
                  placeholder="blurred"
                  className=" mr-6 p-2"
                  alt="Star and Media Seal WPI"
                />{" "}
              </div>

              <div className="w-48">
                <StaticImage
                  src="../images/clients/turbo.jpeg"
                  placeholder="blurred"
                  className=" mr-6 p-2"
                  alt="Turbo and Media Seal WPI"
                />{" "}
              </div>
            </div>

            <div className="flex flex-col md:flex-row items-center justify-between">
              <div className="w-48">
                <StaticImage
                  src="../images/clients/nb.jpeg"
                  placeholder="blurred"
                  className=" mr-6 p-2"
                  alt="NB and Media Seal WPI"
                  // width={120}
                />
              </div>

              <div className="w-48">
                <StaticImage
                  src="../images/clients/cocacola.png"
                  placeholder="blurred"
                  className=" mr-6 "
                  alt="Coca-cola and Media Seal WPI"
                />
              </div>

              <div className="w-48  flex items-center justify-center">
                <StaticImage
                  src="../images/clients/dangote.png"
                  placeholder="blurred"
                  className=" mr-6 p-2"
                  alt="Dangote and Media Seal WPI"
                />
              </div>

              <div className="w-48  flex items-center justify-center">
                <StaticImage
                  src="../images/clients/nile.jpeg"
                  placeholder="blurred"
                  className=" mr-6 p-2"
                  alt="Nile and Media Seal WPI"
                  style={{
                    width: 90,
                  }}
                />{" "}
              </div>

              <div className="w-48">
                <StaticImage
                  src="../images/clients/etisalat.png"
                  placeholder="blurred"
                  className=" mr-6 p-2"
                  alt="Etisalat and Media Seal WPI"
                />{" "}
              </div>

              <div className="w-48">
                <StaticImage
                  src="../images/clients/fayrouz.jpeg"
                  placeholder="blurred"
                  className=" mr-6 p-2"
                  alt="Fayrouz and Media Seal WPI"
                />{" "}
              </div>
            </div>

            <div className="flex flex-col md:flex-row items-center justify-between">
              <div className="w-48">
                <StaticImage
                  src="../images/pg.png"
                  placeholder="blurred"
                  className=" mr-6 p-2"
                  alt="PG and Media Seal WPI"
                  // width={120}
                />
              </div>

              <div className="w-48">
                <StaticImage
                  src="../images/clients/guinness.png"
                  placeholder="blurred"
                  className=" mr-6 "
                  alt="Guinness and Media Seal WPI"
                />
              </div>

              <div className="w-48  flex items-center justify-center">
                <StaticImage
                  src="../images/clients/fanmilk_logo_buttom.webp"
                  placeholder="blurred"
                  className=" mr-6 p-2"
                  alt="Fan Milk and Media Seal WPI"
                  style={{
                    width: 90,
                  }}
                />
              </div>

              <div className="w-48  flex items-center justify-center">
                <StaticImage
                  src="../images/clients/935_logo.png"
                  placeholder="blurred"
                  className=" mr-6 p-2"
                  alt="Dr Brown and  Media Seal WPI"
                  style={{
                    width: 90,
                  }}
                />{" "}
              </div>

              <div className="w-48  flex items-center justify-center">
                <StaticImage
                  src="../images/clients/maggi-logo-DB43B1E9A9-seeklogo.com.png"
                  placeholder="blurred"
                  className=" mr-6 p-2"
                  alt="Maggi and Media Seal WPI"
                  style={{
                    width: 90,
                  }}
                />{" "}
              </div>

              <div className="w-48">
                <StaticImage
                  src="../images/clients/0c396003ea4a884ccfac.png"
                  placeholder="blurred"
                  className=" mr-6 p-2"
                  alt="Lagos Danfo Media Seal WPI"
                />{" "}
              </div>
            </div>

            <div className="flex flex-col md:flex-row items-center justify-between">
              <div className="w-48 flex items-center justify-center">
                <StaticImage
                  src="../images/webimages/5e621fcbdf8a7_1583488971.png"
                  placeholder="blurred"
                  className="mr-6 p-2 "
                  alt="Lagos Danfo Media Seal WPI"
                  style={{
                    maxWidth: 120,
                  }}
                />
              </div>

              <div className="w-48 flex items-center justify-center">
                <StaticImage
                  src="../images/webimages/Mr.-Chef-Logo-Rev-1.png"
                  placeholder="blurred"
                  className=" mr-6  p-2 "
                  alt="Mr Chef and Media Seal WPI"
                  style={{
                    width: 120,
                  }}
                />
              </div>

              <div className="w-48 flex items-center justify-center">
                <StaticImage
                  src="../images/webimages/nestle-9-logo-png-transparent.png"
                  placeholder="blurred"
                  className=" mr-6 p-2 flex items-center justify-center"
                  alt="Nestle and Media Seal WPI"
                  style={{
                    width: 90,
                  }}
                />
              </div>

              <div className="w-48">
                <StaticImage
                  src="../images/clients/huawei.png"
                  placeholder="blurred"
                  className=" mr-6 p-2"
                  alt="Huawei and Media Seal WPI"
                />{" "}
              </div>

              <div className="w-48">
                <StaticImage
                  src="../images/clients/emirate.png"
                  placeholder="blurred"
                  className=" mr-6 p-2"
                  alt="Emirate and Media Seal WPI"
                />{" "}
              </div>

              <div className="w-48">
                <StaticImage
                  src="../images/clients/qatar.png"
                  placeholder="blurred"
                  className=" mr-6 p-2"
                  alt="Qatar and Media Seal WPI"
                />{" "}
              </div>
            </div>

            <div className="flex flex-col md:flex-row items-center justify-between">
              <div className="w-48 flex items-center justify-center">
                <StaticImage
                  src="../images/clients/golden-morn_4.png"
                  placeholder="blurred"
                  className="mr-6 p-2 "
                  alt="Lagos Danfo Media Seal WPI"
                  style={{
                    width: 90,
                  }}
                />
              </div>

              <div className="w-48">
                <StaticImage
                  src="../images/clients/lg-electronics-1-logo-png-transparent.png"
                  placeholder="blurred"
                  className=" mr-6 p-2"
                  alt="Lagos Danfo Media Seal WPI"
                />{" "}
              </div>

              <div className="w-48">
                <StaticImage
                  src="../images/clients/legend.jpeg"
                  placeholder="blurred"
                  className=" mr-6 p-2"
                  alt="Lagos Danfo Media Seal WPI"
                />{" "}
              </div>

              <div className="w-48">
                <StaticImage
                  src="../images/clients/maltina.jpeg"
                  placeholder="blurred"
                  className=" mr-6 p-2"
                  alt="Lagos Danfo Media Seal WPI"
                />{" "}
              </div>

              <div className="w-48">
                <StaticImage
                  src="../images/clients/nescafe-logo.png"
                  placeholder="blurred"
                  className=" mr-6 p-2"
                  alt="Lagos Danfo Media Seal WPI"
                />{" "}
              </div>

              <div className="w-48">
                <StaticImage
                  src="../images/clients/samsung.png"
                  placeholder="blurred"
                  className=" mr-6 p-2"
                  alt="Lagos Danfo Media Seal WPI"
                />{" "}
              </div>
            </div>
          </div>

          <div className="flex flex-col py-12 lg:py-32  px-6 lg:px-16">
            <div className="flex lg:flex-row flex-col">
              <h1 className="text-3xl lg:text-6xl font-bold w-full items-center justify-center pb-8 ">
                Award & Recognition
              </h1>
              {/* <div className="w-full lg:w-2/3"></div> */}
            </div>
            <div className="flex lg:flex-row flex-col">
              <div className="w-full lg:w-1/3"></div>
              <article className=" font-normal pt-8 w-full lg:w-2/3 lg:pr-16">
                <ul>
                  <li className="py-2 font-normal">
                    -| Marketing World – Media Agency of the Year, 2012.
                  </li>
                  <li className="py-2 font-normal">
                    -| The Movers – Outstanding Contribution to the Development
                    of Media Independent Practice in Nigeria 2012.
                  </li>
                  <li className="py-2 font-normal">
                    -| Marketing World Award (MWA) – Media Agency of the Year,
                    2013.
                  </li>
                  <li className="py-2 font-normal">
                    -| APCON – Most Compliant Agency, 2013.
                  </li>
                  <li className="py-2 font-normal">
                    -| Marketing Edge Awards – Outstanding Media Buying Agency
                    of the Decade, 2013.
                  </li>
                  <li className="py-2 font-normal">
                    -| Marketing Edge Awards – Outstanding Media Buying Agency
                    of the Decade, 2014.
                  </li>
                  <li className="py-2 font-normal">
                    -| Marketing Edge Awards – Outstanding Media Buying Agency
                    of the Decade, 2015.
                  </li>
                  <li className="py-2 font-normal">
                    -| Nigerian Brand Award – Most Innovative and Impactful
                    Media Independent Agency of the Year, 2015.
                  </li>
                  <li className="py-2 font-normal">
                    -| Brand King – Best Advertising Agency of the Year, 2015.
                  </li>
                  <li className="py-2 font-normal">
                    -| WAQPSA – West African Best Quality Media Planning and
                    Buying Agency of the Year, 2015.
                  </li>
                  <li className="py-2 font-normal">
                    -| WAIA – Best Media Independent Company in West African for
                    the year 2017.
                  </li>
                  <li className="py-2 font-normal">
                    -| Business Partnership Award, 2018.
                  </li>
                  <li className="py-2 font-light">
                    -| African Brand Congress (ABC) – Media Planning Agency of
                    the year, 2019.
                  </li>
                  <li className="py-2 font-normal">
                    -| Institute of Brand Management of Nigeria (IBMN) – West
                    Africa’s Best World Class Quality Media Planning Agency of
                    the Decade, 2021.
                  </li>
                </ul>
              </article>
            </div>
          </div>

          <div className="flex flex-col py-12 lg:py-32 px-6 lg:px-16 bg-black text-white">
            <div className="flex lg:flex-row flex-col">
              <h1 className="text-3xl lg:text-6xl font-bold w-full  items-center justify-center pb-8 ">
                Our Work Culture
              </h1>
              {/* <div className="w-full lg:w-2/3"></div> */}
            </div>
            <div className="flex lg:flex-row flex-col">
              <div className="w-full lg:w-1/3"></div>
              <article className=" font-thin pt-8 w-full lg:w-2/3 lg:pr-16">
                <div className="flex lg:flex-row flex-col">
                  <div className="flex flex-col w-full lg:w-1/2  lg:mr-8">
                    <h1 className="text-xl font-bold items-center ">
                      Customer at heart
                    </h1>
                    <div className="">
                      <p className="font-light">
                        {" "}
                        Target-centric approach to planning and buying. Total
                        commitment to research-based planning as a
                        brand-building tool.
                      </p>
                    </div>
                  </div>

                  <div className="flex flex-col w-full lg:w-1/2 pt-8 lg:pt-0">
                    <h1 className="text-xl font-bold items-center ">
                      Confidentiality
                    </h1>
                    <div className="">
                      <p className="font-light">
                        {" "}
                        Confidentiality and security of client's information
                      </p>
                    </div>
                  </div>
                </div>

                <div className="flex lg:flex-row flex-col pt-12">
                  <div className="flex flex-col w-full lg:w-1/2 lg:mr-8">
                    <h1 className="text-xl font-bold items-center ">
                      Professionalism
                    </h1>
                    <div className="">
                      <p className="font-light">
                        {" "}
                        Expertise in developing and implementing marketing
                        strategies that drive and produce results
                      </p>
                    </div>
                  </div>

                  <div className="flex flex-col w-full lg:w-1/2 pt-8 lg:pt-0">
                    <h1 className="text-xl font-bold items-center ">
                      Transparency
                    </h1>
                    <div className="">
                      <p className="font-light">
                        {" "}
                        Transparency and integrity in all transactions
                      </p>
                    </div>
                  </div>
                </div>
              </article>
            </div>

            <div className="flex pt-16">
              <div className="flex flex-col w-1/3  ">
                <StaticImage
                  alt="insight driven media planning"
                  src="../images/about/IMG_20201219_151028_879.jpg"
                  placeholder="blurred"
                  className=" lg:mt-6 lg:mx-6"
                />

                <StaticImage
                  alt="insight driven media planning"
                  src="../images/about/IMG_20201219_152347_943.jpg"
                  placeholder="blurred"
                  className=" lg:mt-6 lg:mx-6"
                />

                {/* <StaticImage
                  alt="insight driven media planning"
                  src="../images/about/IMG_20201219_151028_879.jpg"
                  placeholder="blurred"
                  className=" mt-6 mx-6"
                /> */}
              </div>

              <div className="flex flex-col w-1/3 pt-8 lg:pt-24">
                <StaticImage
                  alt="insight driven media planning"
                  src="../images/webimages-2/IMG_1730.jpg"
                  placeholder="blurred"
                  className=" lg:mt-6 lg:mx-6"
                />

                <StaticImage
                  alt="insight driven media planning"
                  src="../images/webimages-2/IMG_1734.jpg"
                  placeholder="blurred"
                  className=" lg:mt-6 lg:mx-6"
                />

                {/* <StaticImage
                  alt="insight driven media planning"
                  src="../images/clients/mediaseal_1.jpeg"
                  placeholder="blurred"
                  className=" mt-6 mx-6"
                /> */}
              </div>

              <div className="flex flex-col w-1/3">
                <StaticImage
                  alt="insight driven media planning"
                  src="../images/webimages-2/IMG_7474.jpeg"
                  placeholder="blurred"
                  className=" lg:mt-6 lg:mx-6"
                />

                <StaticImage
                  alt="insight driven media planning"
                  src="../images/webimages-2/IMG_7475.jpg"
                  placeholder="blurred"
                  className=" lg:mt-6 lg:mx-6"
                />

                {/* <StaticImage
                  alt="insight driven media planning"
                  src="../images/clients/mediaseal_1.jpeg"
                  placeholder="blurred"
                  className=" mt-6 mx-6"
                /> */}
              </div>
            </div>
          </div>

          <div
            className="flex flex-col py-12 lg:py-32 px-6 lg:px-16"
            id="career"
          >
            <div className="flex flex-col lg:flex-row">
              <h1 className=" text-3xl lg:text-6xl font-bold w-full  items-center justify-center ">
                Career Opportunities
              </h1>
              {/* <div className="w-full lg:w-2/3"></div> */}
            </div>
            <div className="flex flex-col lg:flex-row">
              {/* <div className="w-full lg:w-1/3"></div> */}
              <article className=" font-thin pt-8 w-full lg:pr-16">
                <p className="pb-4 font-light">
                  MediaSeal is a dynamic, exciting place to work. We hire
                  exceptional people, and everyone of them is empowered to think
                  independently, take initiative and be innovative. Have you
                  read about our work culture? Do you think you are a good
                  culture fit? Are you always evolving? Then keep tabs on
                  openings!
                </p>
                <a
                  href="mailto:mediaseal@mediaseal-ng.com"
                  className="underline mt-8 font-light"
                >
                  Send us an email
                </a>
              </article>
            </div>
          </div>
        </Layout>
      </div>
    )
}

export default about
